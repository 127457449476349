import { ToastId } from '@chakra-ui/react'
import { getReadableFileSize } from '@opengovsg/design-system-react'
import { useRef } from 'react'
import { WretchError } from 'wretch/resolver'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

import useToast from '~components/useToast'
import { useRumTimedEvent } from '~hooks/useRumTimedEvent'
import { useSetWorkspaceIdPathParam } from '~hooks/useSetPathParams'
import { api } from '~lib/api'
import { CUSTOM_RUM_EVENTS } from '~lib/rum'
import {
  EMAIL_MULTIPLE_DOWNLOAD_SIZE,
  MAX_MULTIPLE_DOWNLOAD_SIZE,
} from '~shared/constants'
import { AlbumResponseDto } from '~shared/types/albums.dto'
import { CheckDownloadJobResponseDto } from '~shared/types/photos.dto'
import { WorkspaceResponseDto } from '~shared/types/workspaces.dto'
import { createSelectors } from '~utils/createSelectors'
import { generateWorkspacePathPrefix } from '~utils/workspacePath'

import { DownloadingToast } from './components/DownloadingToast'
import { WORKSPACE_NOT_FOUND } from './constants'
import { useDisclosureStore } from './useDisclosureStore'
import { useFiltersStore } from './useFiltersStore'

export type WorkspaceStore = {
  currentWorkspace: WorkspaceResponseDto | null
  setCurrentWorkspace: (workspace: WorkspaceResponseDto | null) => void
  allWorkspaces: WorkspaceResponseDto[]
  setAllWorkspaces: (workspaces: WorkspaceResponseDto[]) => void
  currentAlbum: AlbumResponseDto | null
  setCurrentAlbum: (album: AlbumResponseDto | null) => void
  checkedMedia: CheckedMedia
  setCheckedMedia: (checkedMedia: CheckedMedia) => void
  setCheckedMediaCallback: (fn: (prev: CheckedMedia) => CheckedMedia) => void
  isExpandMediaMode: boolean
  setIsExpandMediaMode: (isExpandMediaMode: boolean) => void
  setIsExpandMediaModeCallback: (fn: (prev: boolean) => boolean) => void
  isDirty: boolean
  setIsDirty: (isDirty: boolean) => void
  isDownloadInProgress: boolean
  setIsDownloadInProgress: (isDownloadInProgress: boolean) => void
  isSelectState: boolean
  setIsSelectState: (isSelectState: boolean) => void
  setIsSelectStateCallback: (fn: (prev: boolean) => boolean) => void
  workspacesInRecycleBin: WorkspaceResponseDto[]
  setWorkspacesInRecycleBin: (
    workspacesInRecycleBin: WorkspaceResponseDto[],
  ) => void
  selectedWorkspaceInRecycleBin: WorkspaceResponseDto | null
  setSelectedWorkspaceInRecycleBin: (
    workspace: WorkspaceResponseDto | null,
  ) => void
  selectedAlbumInRecycleBin: AlbumResponseDto | null
  setSelectedAlbumInRecycleBin: (album: AlbumResponseDto | null) => void
  rangeStartHistory: number[]
  setRangeStartHistory: (rangeStartHistory: number[]) => void
  addToRangeStartHistory: (index: number) => void
  removeFromRangeStartHistory: (index: number) => void
  clearRangeStartHistory: () => void
  isTagView: boolean
  setIsTagView: (isTagView: boolean) => void
}

export type CheckedMedia = {
  ids: Set<string>
  totalSize: number
}

export const defaultCheckedMedia: CheckedMedia = {
  ids: new Set<string>(),
  totalSize: 0,
}

const WorkspaceStore = create<WorkspaceStore>()(
  devtools(
    (set) => ({
      allWorkspaces: [],
      setAllWorkspaces: (allWorkspaces: WorkspaceResponseDto[]) =>
        set({ allWorkspaces: [...allWorkspaces] }),
      currentWorkspace: null,
      setCurrentWorkspace: (currentWorkspace: WorkspaceResponseDto | null) =>
        set({
          currentWorkspace,
        }),
      currentAlbum: null,
      setCurrentAlbum: (currentAlbum: AlbumResponseDto | null) =>
        set({
          currentAlbum,
        }),
      checkedMedia: defaultCheckedMedia,
      setCheckedMedia: (checkedMedia: CheckedMedia) =>
        set({
          checkedMedia,
        }),
      setCheckedMediaCallback: (fn: (prev: CheckedMedia) => CheckedMedia) => {
        set((state) => ({ checkedMedia: fn(state.checkedMedia) }))
      },
      isExpandMediaMode: false,
      setIsExpandMediaMode: (isExpandMediaMode: boolean) =>
        set({ isExpandMediaMode }),
      setIsExpandMediaModeCallback: (fn: (prev: boolean) => boolean) => {
        set((state) => ({ isExpandMediaMode: fn(state.isExpandMediaMode) }))
      },
      isDirty: false,
      setIsDirty: (isDirty: boolean) => set({ isDirty }),
      isDownloadInProgress: false,
      setIsDownloadInProgress: (isDownloadInProgress: boolean) =>
        set({
          isDownloadInProgress,
        }),
      isSelectState: false,
      setIsSelectState: (isSelectState: boolean) =>
        set({
          isSelectState,
        }),
      setIsSelectStateCallback: (fn: (prev: boolean) => boolean) => {
        set((state) => ({ isSelectState: fn(state.isSelectState) }))
      },
      workspacesInRecycleBin: [],
      setWorkspacesInRecycleBin: (
        workspacesInRecycleBin: WorkspaceResponseDto[],
      ) =>
        set({
          workspacesInRecycleBin,
        }),
      selectedWorkspaceInRecycleBin: null,
      setSelectedWorkspaceInRecycleBin: (
        selectedWorkspaceInRecycleBin: WorkspaceResponseDto | null,
      ) =>
        set({
          selectedWorkspaceInRecycleBin,
        }),
      selectedAlbumInRecycleBin: null,
      setSelectedAlbumInRecycleBin: (
        selectedAlbumInRecycleBin: AlbumResponseDto | null,
      ) =>
        set({
          selectedAlbumInRecycleBin,
        }),
      rangeStartHistory: [],
      setRangeStartHistory: (rangeStartHistory: number[]) =>
        set({ rangeStartHistory: [...rangeStartHistory] }),
      addToRangeStartHistory: (index: number) =>
        set((state) => ({
          rangeStartHistory: [...state.rangeStartHistory, index],
        })),
      removeFromRangeStartHistory: (index: number) =>
        set((state) => ({
          rangeStartHistory: state.rangeStartHistory.filter(
            (rangeIdx) => rangeIdx !== index,
          ),
        })),
      clearRangeStartHistory: () => set({ rangeStartHistory: [] }),
      isTagView: false,
      setIsTagView: (isTagView: boolean) => set({ isTagView }),
    }),
    { enabled: process.env.NODE_ENV !== 'production' },
  ),
)

/* 
  Derived values, put into hooks
  https://github.com/pmndrs/zustand/issues/108
*/
export const useIsAbleToDownload: () => boolean = () => {
  const checkedMedia = useWorkspaceStore((state) => state.checkedMedia)
  return (
    checkedMedia.ids.size == 1 ||
    checkedMedia.totalSize <= MAX_MULTIPLE_DOWNLOAD_SIZE
  )
}

export const useGetReadableTotalSizeOfCheckedMedia: () => string = () => {
  const checkedMediaTotalSize = useWorkspaceStore.use.checkedMedia().totalSize
  return getReadableFileSize(checkedMediaTotalSize)
}

/* 
  Hooks for these set functions as they require the use of other hooks such as useToast
  cannot invoke hooks inside a zustand store as it exists outside React
  https://stackoverflow.com/questions/76908725/i-need-to-call-a-hook-inside-a-zustand-action
*/
export const useSetCurrentWorkspaceById: () => (id: string) => void = () => {
  const allWorkspaces = useWorkspaceStore((state) => state.allWorkspaces)
  const currentWorkspace = useWorkspaceStore((state) => state.currentWorkspace)
  const setCurrentWorkspace = useWorkspaceStore(
    (state) => state.setCurrentWorkspace,
  )
  const setCurrentAlbum = useWorkspaceStore((state) => state.setCurrentAlbum)
  const toast = useToast()
  return (id) => {
    if (allWorkspaces) {
      const targetWorkspace = allWorkspaces.find(
        (workspace) => workspace.id === id,
      )
      if (targetWorkspace == undefined) {
        toast({
          title: `Workspace does not exist / You do not have access to this workspace`,
          status: 'error',
        })
        throw new Error(
          'Workspace does not exist / You do not have access to this workspace',
          { cause: WORKSPACE_NOT_FOUND },
        )
      } else {
        if (targetWorkspace.id === currentWorkspace?.id) return // do nothing if target is current
        setCurrentAlbum(null) // reset the album first to prevent fetches between unsynced workspace-albums
        setCurrentWorkspace(targetWorkspace)
      }
    }
  }
}

export const useCloseEditDrawer: () => () => void = () => {
  const setIsExpandMediaMode = useWorkspaceStore.use.setIsExpandMediaMode()
  const onEditDrawerClose = useDisclosureStore.use.onEditDrawerClose()
  return () => {
    setIsExpandMediaMode(false)
    onEditDrawerClose()
  }
}

export const useSetCurrentWorkspaceToPersonal: () => () => void = () => {
  const allWorkspaces = useWorkspaceStore.use.allWorkspaces()
  const setCurrentAlbum = useWorkspaceStore.use.setCurrentAlbum()
  const setCheckedMedia = useWorkspaceStore.use.setCheckedMedia()
  const closeEditDrawer = useCloseEditDrawer()
  const clearAllFilters = useFiltersStore.use.clearAllFilters()
  const clearRangeStartHistory = useWorkspaceStore.use.clearRangeStartHistory()
  const setWorkspaceIdPathParam = useSetWorkspaceIdPathParam()
  return () => {
    if (allWorkspaces.length > 0) {
      setCurrentAlbum(null) // reset the album first to prevent fetches between unsynced workspace-albums
      setWorkspaceIdPathParam(
        allWorkspaces.find((workspace) => workspace.isDefault)?.id || null,
      )
      clearAllFilters()
      setCheckedMedia(defaultCheckedMedia)
      closeEditDrawer()
      clearRangeStartHistory()
    }
  }
}

export const useHandleChangeWorkspace: () => (
  workspaceId: string,
) => void = () => {
  const closeEditDrawer = useCloseEditDrawer()
  const setCheckedMedia = useWorkspaceStore.use.setCheckedMedia()
  const setWorkspaceIdPathParam = useSetWorkspaceIdPathParam()
  const clearAllFilters = useFiltersStore.use.clearAllFilters()
  const clearRangeStartHistory = useWorkspaceStore.use.clearRangeStartHistory()
  const onDirtyModalDisclosureOpen =
    useDisclosureStore.use.onDirtyModalDisclosureOpen()
  const isDirty = useWorkspaceStore.use.isDirty()
  const setIsTagView = useWorkspaceStore.use.setIsTagView()
  return (workspaceId: string) => {
    // if dirty open modal
    if (isDirty) return onDirtyModalDisclosureOpen()
    // else shift workspace and reset gallery states
    setWorkspaceIdPathParam(workspaceId)
    clearAllFilters()
    setCheckedMedia(defaultCheckedMedia)
    closeEditDrawer()
    clearRangeStartHistory()
    setIsTagView(false)
  }
}

export const useHandleChangeRecycleBinSelectedWorkspace: () => () => void =
  () => {
    const closeEditDrawer = useCloseEditDrawer()
    const setSelectedAlbumInRecycleBin =
      useWorkspaceStore.use.setSelectedAlbumInRecycleBin()
    const setCheckedMedia = useWorkspaceStore.use.setCheckedMedia()
    const clearRangeStartHistory =
      useWorkspaceStore.use.clearRangeStartHistory()

    return () => {
      // reset the album first to prevent fetches between unsynced workspace-albums
      setSelectedAlbumInRecycleBin(null)
      setCheckedMedia(defaultCheckedMedia)
      closeEditDrawer()
      clearRangeStartHistory()
    }
  }

export const useHandleDownload: (currentMedia?: string) => () => void = (
  currentMedia,
) => {
  const currentWorkspace = useWorkspaceStore.use.currentWorkspace()
  const currentAlbum = useWorkspaceStore.use.currentAlbum()
  const checkedMedia = useWorkspaceStore.use.checkedMedia()
  const isDownloadInProgress = useWorkspaceStore.use.isDownloadInProgress()
  const setIsDownloadInProgress =
    useWorkspaceStore.use.setIsDownloadInProgress()
  const toast = useToast()
  const { recordTimeEvent } = useRumTimedEvent(
    CUSTOM_RUM_EVENTS.multipleFilesDownloadTime,
  )

  const toastIdRef = useRef<ToastId>() // To close toast that displays an intermediate status for downloads

  const closeToast = () => {
    if (toastIdRef.current) {
      toast.close(toastIdRef.current)
    }
  }

  const handleDownloadError = (error: WretchError | Error) => {
    // Provide feedback to user via progress tile
    if (error instanceof WretchError) {
      console.error((error.json as { message: string }).message)
    } else if (error.message) {
      console.error(error.message)
    }

    toast({
      title:
        'Could not download files from server. Please refresh and try again.',
      status: 'error',
    })
  }

  const triggerDownloadElement = (downloadUrl: string, fileName?: string) => {
    const downloadLink = document.createElement('a')
    downloadLink.href = downloadUrl
    downloadLink.download = fileName ? fileName : '' // if doesn't exist, still sets download prop to allow download from component
    downloadLink.click()

    // Cleanup of anchor and URL
    window.URL.revokeObjectURL(downloadLink.href)
    downloadLink.remove()
  }

  const handleSingleDownload = (url: string, photoId: string) => {
    api
      .url(`${url}/single`)
      .post({
        photoId,
      })
      .text()
      .then((downloadUrl) => {
        triggerDownloadElement(downloadUrl)

        // Final toast to user to indicate that download was triggered on their browser.
        closeToast()
        toastIdRef.current = toast({
          position: 'bottom',
          render: () => (
            <DownloadingToast
              closeToast={closeToast}
              title="Download starting!"
            />
          ),
        })
      })
      .catch((error: WretchError | Error) => {
        closeToast()
        handleDownloadError(error)
      })
      .finally(() => {
        setIsDownloadInProgress(false)
      })
  }

  const pollOrEmailForMultipleDownloads = (url: string, jobId: string) => {
    const shouldPoll = checkedMedia.totalSize < EMAIL_MULTIPLE_DOWNLOAD_SIZE

    toastIdRef.current = toast({
      position: 'bottom',
      ...(shouldPoll && { duration: null }), // If shouldPoll, keep toast open, else, close toast after default duration
      render: () => (
        <DownloadingToast closeToast={shouldPoll ? undefined : closeToast} />
      ),
    })

    // Only poll for status if file size is under email threshold
    if (shouldPoll) {
      checkJobForMultipleDownloads({
        url,
        jobId,
        numOfFiles: checkedMedia.ids.size,
        sizeOfFiles: checkedMedia.totalSize,
      })
    }
  }
  const createJobForMultipleDownloads = (url: string) => {
    api
      .url(`${url}/multiple`)
      .post({
        photoIds: Array.from(checkedMedia.ids),
      })
      .json<{ jobId: string }>()
      .then(({ jobId }) => pollOrEmailForMultipleDownloads(url, jobId))
      .catch((error: WretchError | Error) => {
        handleDownloadError(error)
      })
      .finally(() => {
        setIsDownloadInProgress(false)
      })
  }

  const checkJobForMultipleDownloads = ({
    url,
    jobId,
    numOfFiles,
    sizeOfFiles,
  }: {
    url: string
    jobId: string
    numOfFiles: number
    sizeOfFiles: number
  }) => {
    const startTime = Date.now()
    const periodSeconds = 3
    const timeoutSeconds = 10 * 60
    let attempts = Math.floor(timeoutSeconds / periodSeconds)
    const interval = setInterval(() => {
      if (attempts <= 1) clearInterval(interval)
      api
        .url(`${url}/check`)
        .post({
          jobId,
        })
        .json<CheckDownloadJobResponseDto>()
        .then((data) => {
          if (data.error) throw new Error(data.message)
          if (data.url) {
            triggerDownloadElement(data.url)
            closeToast()
            recordTimeEvent({
              startTime,
              isSuccessful: true,
              metadata: {
                numOfFiles,
                sizeOfFiles,
              },
            })
            clearInterval(interval)
          }
        })
        .catch((error: WretchError | Error) => {
          handleDownloadError(error)
          closeToast()
          recordTimeEvent({
            startTime,
            isSuccessful: false,
            error,
            metadata: {
              numOfFiles,
              sizeOfFiles,
            },
          })
          clearInterval(interval)
        })
        .finally(() => {
          attempts--
        })
    }, periodSeconds * 1000)
  }

  return () => {
    // verify if there are any inflights download before init
    if (isDownloadInProgress) return

    // Trigger a toast with a spinner to notify the user that Pinpoint is processing the request. Without it, for large
    // downloads, there may be an awkward period where the user does not see any response on the UI, and may think that
    // the download request did not go through.
    if (currentWorkspace === null || currentAlbum === null) {
      toast({
        title: `There was an error, please refresh the page and try again`,
        status: 'error',
      })
      console.error('currentWorkspace is not initialised')
      return
    }

    toastIdRef.current = undefined

    // Ensure only one download happens at a time
    setIsDownloadInProgress(true)

    const baseDownloadApiUrl = `${generateWorkspacePathPrefix(
      currentWorkspace.id,
      currentAlbum.id,
    )}/photos/download`

    // Prioritise current media if present
    if (currentMedia) {
      try {
        handleSingleDownload(baseDownloadApiUrl, currentMedia)
      } catch (error) {
        console.log(error)
        throw new Error(
          'Something went wrong. Please refresh the page and try again.',
        )
      }
      // Use checked media
    } else if (checkedMedia.ids.size === 1) {
      try {
        const photoId = Array.from(checkedMedia.ids)[0]
        handleSingleDownload(baseDownloadApiUrl, photoId)
      } catch (error) {
        // in case of indexing errors
        console.log(error)
        throw new Error(
          'Something went wrong. Please refresh the page and try again.',
        )
      }
    } else {
      createJobForMultipleDownloads(baseDownloadApiUrl)
    }
  }
}

export const useWorkspaceStore = createSelectors(WorkspaceStore)
