import { Box, CloseButton, Flex, Text } from '@chakra-ui/react'

import { Loader } from '../Loader'

interface DownloadingToastProps {
  closeToast?: () => void
  title?: string
}
export const DownloadingToast = ({
  closeToast,
  title,
}: DownloadingToastProps) => {
  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      minWidth={{ base: undefined, lg: '33.5rem' }}
      padding="1rem 1.5rem"
      borderRadius="0.625rem"
      background="interaction.sub.default"
    >
      {title ? (
        <Text as="span" textStyle="subhead-1">
          {title}
        </Text>
      ) : closeToast ? (
        <Box>
          <Text as="span" textStyle="subhead-1">
            Your download is <b>too large</b> and will be <b>sent via email</b>
          </Text>
          <Text textStyle="body-2">You may navigate away from this page.</Text>
        </Box>
      ) : (
        <Box>
          <Text as="span" textStyle="subhead-1">
            Preparing your download. <b>Do not refresh or close the page.</b>
          </Text>

          <Text textStyle="body-2">
            Your download will start automatically when ready.
          </Text>
        </Box>
      )}
      {closeToast ? (
        <CloseButton
          onClick={closeToast}
          marginLeft="1rem"
          width="1.5rem"
          height="1.5rem"
        />
      ) : (
        <Loader marginLeft="0.75rem" width="1.5rem" height="1.5rem" />
      )}
    </Flex>
  )
}
